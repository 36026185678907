import { TokenPayloadUser, UserLite } from "@/types";

class UserUtil {
	public createUserLiteFromTokenPayload(tokenPayloadUser: TokenPayloadUser): UserLite {
		return {
			userId: tokenPayloadUser.id,
			firstName: tokenPayloadUser.fn,
			lastName: tokenPayloadUser.ln,
			email: tokenPayloadUser.e,
			memberId: tokenPayloadUser.mid,
		};
	}
	public fullName(user: UserLite): string {
		const glue = user.firstName && user.lastName ? " " : "";
		return (user.firstName ?? "") + glue + (user.lastName ?? "");
	}
}

export default new UserUtil();
