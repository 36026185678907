
import { defineComponent } from "vue";
import Query from "@/components/Query.vue";
import Markdown from "@/components/Markdown.vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import {
	PageByCodeQuery,
	PageByCodeQueryVariables,
	PageByCodeDocument,
	PageFragment,
} from "@/api/generated/graphql-operations";

export default defineComponent({
	name: "EntityPage",
	components: {
		Query,
		Markdown,
	},
	props: {
		pageCode: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const { result, loading, error } = useQuery<PageByCodeQuery, PageByCodeQueryVariables>(PageByCodeDocument, () => ({
			page: props.pageCode,
		}));

		const page = useResult(result, null, result => result.page as PageFragment);

		// const filterControllers = [
		//   new LengthFilterController(),
		// ];

		return { page, props };
	},
});
