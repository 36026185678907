import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Leaflet from "@/views/Leaflet.vue";
import Search from "@/views/Search.vue";
import UndergroundCavity from "@/views/UndergroundCavity.vue";
import EntityPage from "@/views/EntityPage.vue";
import Login from "@/views/Login.vue";
import { api } from "@/api/Api";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/karta",
		name: "Karta",
		component: Leaflet,
	},
	{
		path: encodeURI("/sök"),
		alias: ["/sök", "/sok", "/search"],
		// redirect: "/sok",
		name: "Search",
		component: Search,
	},
	{
		path: "/system",
		name: "System",
		component: () => import(/* webpackChunkName: "system" */ "../views/System.vue"),
	},
	{
		path: "/poc",
		name: "PocView",
		component: () => import(/* webpackChunkName: "poc" */ "../views/PocView.vue"),
	},
	{
		path: "/om",
		alias: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		// webpackChunkName.. är ett direktiv i e kommentar till Webpack
		// https://router.vuejs.org/guide/advanced/lazy-loading.html
		component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		props: route => ({ jwt: route.query.jwt }),
		meta: { tokenRefresh: false },
	},
	{
		path: encodeURI("/Sveriges_längsta_grottor"),
		alias: ["/Sveriges_längsta_grottor", "/längsta", "/longest"],
		name: "Longest",
		component: EntityPage,
		props: { pageCode: "Sveriges_längsta_grottor" },
	},
	{
		path: encodeURI("/Landskapsgrottor"),
		name: "Landskapsgrottor",
		component: EntityPage,
		props: { pageCode: "Landskapsgrottor" },
	},
	{
		path: "/grotta/:slugSuffix",
		// Varianter här bör laddas från evironment variables (För språkstöd etc)
		alias: ["/grottips/:slugSuffix", "/:slugSuffix"],
		name: "UndergroundCavity",
		component: UndergroundCavity,
		props: true,
	},

	// {
	// 	path: "/:slug",
	// 	name: "Slug",
	// 	component: SlugView,
	// 	props: true,
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

// router.beforeEach((from, to, next) => {
// 	console.log('From');
// 	console.log(from);
// 	console.log('beforeEach');
// 	next();
// });

// Vid sidladdning gör vi ett direkt anrop för att få accessToken och inloggning.
// Detta kan dock spärras för en route, genom att ange meta.tokenRefresh = false
router.afterEach((to, from) => {
	if (to.meta.tokenRefresh ?? true) {
		api.init();
	}
});

export default router;
