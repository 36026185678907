
import { defineComponent, computed, PropType } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import {
	SearchQuery,
	SearchQueryVariables,
	SearchDocument,
	EntityCodeType,
	UndergroundCavityFragment,
} from "@/api/generated/graphql-operations";
import { Filters } from "./filter/Filters";

export default defineComponent({
	name: "QueryResult",
	components: {},
	props: {
		filters: {
			type: Object as PropType<Filters>,
			required: true,
		},
		limit: {
			type: Number,
			required: false,
		},
		linkToSearch: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const allCombinedFilters = computed(() => props.filters.controllers.flatMap(c => c.outputFilters.value));
		const activeSearch = computed(() => {
			return allCombinedFilters.value.length > 0;
		});

		const { result, loading, error, fetchMore } = useQuery<SearchQuery, SearchQueryVariables>(
			SearchDocument,
			() => ({
				defaultCodeType: EntityCodeType.Ssf,
				filters: allCombinedFilters.value.map(filterItem => filterItem.filter),
				limit: props.limit, //Göra reaktiv?
			}),
			() => ({
				enabled: activeSearch.value,
			}),
		);

		const undergroundCavityConnection = useResult(result, null, result => {
			return result.search.undergroundCavityConnection as SearchQuery["search"]["undergroundCavityConnection"] & {
				edges: Array<{ node: UndergroundCavityFragment }>;
			};
		});
		// Fler kan läggas till som egna rader:
		//const articleList = useResult(result, null, result => (result.search.articleList as Array<ArticleFragment>));

		const toSearch = computed(() => {
			if (props.linkToSearch) {
				return { name: "Search", query: { q: props.filters.queryQ.value } };
			}
			return null;
		});
		// https://stackoverflow.com/questions/40848348/spread-an-array-of-objects-into-a-parent-object
		return {
			activeSearch,
			undergroundCavityConnection,
			loading,
			error,
			allCombinedFilters,
			toSearch,
		};
	},
});
