
import { defineComponent, watch, ref, shallowRef, watchEffect } from "vue";
import { useRoute } from "vue-router";
import Layouts from "@/layouts";

export default defineComponent({
	name: "Layout",
	components: {
		... Layouts,
	},
	setup() {
		const layout = shallowRef(Layouts.Default);
		const route = useRoute();
		//const breadcrumbs = ref([]);
		//const pageTitle = ref("");
		// const activeRoute = ref("");
		const matchedRoute = ref(false);
		// watch(route, () => ... kan inte användas pga deep watch inte är tillåtet.
		// varningen: Avoid app logic that relies on enumerating keys on a component instance. The keys will be empty in production mode to avoid performance overhead. 
		// Och fungerar watchEffect bättre.
		watchEffect(() => (matchedRoute.value = route.matched.length > 0));

		watch(
			() => route.meta,
			async meta => {
				//try {
					layout.value = Layouts[meta.layout as string] ?? Layouts.Default;
					//breadcrumbs.value = meta?.breadcrumbs || [];
					//pageTitle.value = meta.pageTitle || "";
					//activeRoute.value = meta.activeRoute || "";
				//} catch (e) {
				//	layout.value = Layouts.Default;
				//}
			},
			{ immediate: true },
		);

		return { layout/*, breadcrumbs*//*, pageTitle*//*, activeRoute*/, matchedRoute };
	},
});
