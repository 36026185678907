
import { defineComponent, computed } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import {
	UndergroundCavityQuery,
	UndergroundCavityQueryVariables,
	UndergroundCavityDocument,
	EntityCodeType,
	UndergroundCavityFragment,
} from "@/api/generated/graphql-operations";
import { useRouter } from "vue-router";
import BasenUtil from "@/util/BasenUtil";
import MarkdownBlock from "@/components/MarkdownBlock.vue";
import { useApi } from "@/api/Api";

export default defineComponent({
	name: "UndergroundCavity",
	components: {
		MarkdownBlock,
	},
	props: {
		slugSuffix: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const router = useRouter();

		const { result, loading, error, onResult } = useQuery<UndergroundCavityQuery, UndergroundCavityQueryVariables>(
			UndergroundCavityDocument,
			() => ({
				slugSuffix: props.slugSuffix.replace(/ /g, "_"),
				defaultCodeType: EntityCodeType.Ssf,
			}),
		);
		// Uppdatarera till mainSlug som URL:
		onResult(result => {
			const undergroundCavity = result?.data?.undergroundCavity as UndergroundCavityFragment | undefined;
			if (undergroundCavity?.slugMain && undergroundCavity.slugMain != router.currentRoute.value.path) {
				router.replace({ path: undergroundCavity.slugMain, force: true });
			}
		});

		const undergroundCavity = useResult(result, null, result => result.undergroundCavity as UndergroundCavityFragment);

		const pageTitle = computed(() => {
			if (undergroundCavity.value) {
				return undergroundCavity.value.name;
				// I väntan på att sidan laddas visas en rubrik gjord av den slug som skickats med.
			} else if (+props.slugSuffix > 0) {
				return `SSF Riksnummer: ${+props.slugSuffix}`;
			} else {
				return props.slugSuffix.charAt(0).toUpperCase() + props.slugSuffix.slice(1).replace(/_/g, " ");
			}
		});
		return { pageTitle, undergroundCavity, loading, error, BasenUtil, ...useApi() };
	},
});
