import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")
  const _component_Query = _resolveComponent("Query")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock(_component_Card, null, {
    content: _withCtx(() => [
      _createVNode("h1", _hoisted_1, _toDisplayString(_ctx.page?.title ?? _ctx.pageCode.replace(/_/g, " ")), 1),
      (_ctx.page)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            (_ctx.page.descriptionHeader)
              ? (_openBlock(), _createBlock(_component_Markdown, {
                  key: 0,
                  markdown: _ctx.page.descriptionHeader
                }, null, 8, ["markdown"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Query, {
              query: _ctx.page.query
            }, null, 8, ["query"]),
            (_ctx.page.descriptionFooter)
              ? (_openBlock(), _createBlock(_component_Markdown, {
                  key: 1,
                  markdown: _ctx.page.descriptionFooter
                }, null, 8, ["markdown"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}