
import { defineComponent } from "vue";
//import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import SearchBar from "@/components/SearchBar.vue"; // @ is an alias to /src
import Markdown from "@/components/Markdown.vue";

const markdownText = `Välkommen till grottor.se. Här kan du läsa om alla kända grottor i Sverige. Vi har gjort viss information
tillgängliga för alla, bland annat kan du läsa om alla [landskapsgrottor](/Landskapsgrottor).

Informationen kommer ifrån <a href="https://speleo.se/" target="_blank">Sveriges Speleologförbund</a>s
grottdatabas, som har byggts upp av förbundets medlemmar under många år.

Sidan är just nu i en tidig BETA-version, som saknar mycket funktionalitet, data och färdig design.<br />
Än så länge finns ingen geografisk information importerad.

Som medlem i Sveriges Speleologförbund kan du ta del av all information om alla grottor och även använda
direktlänkar till grottdatabasen.`;

export default defineComponent({
	name: "Home",
	components: {
		SearchBar,
		Markdown,
	},
	setup() {
		const appName: string | undefined = process.env.VUE_APP_NAME;
		const version: string | undefined = process.env.VUE_APP_VERSION;
		return { appName, version, markdownText };
	},
});
