
import { defineComponent, watch, Ref, computed } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import searchStore from "@/stores/searchStore";
import marked from "marked";
import AutoComplete from "primevue/autocomplete";

import { FilterSuggestion, FilterItem } from "./filter";
import { Filters, searchFilters } from "./filter/Filters";
import LengthFilter from "./filter/LengthFilter.vue";
import CommonFilter from "./filter/CommonFilter.vue";
import QueryResult from "./QueryResult.vue";
import { SuggestionSource, SuggestionType } from "@/api/generated/graphql-operations";
import CodeFilterController from "./filter/CodeFilterController";
import LengthFilterController from "./filter/LengthFilterController";
import router from "@/router";

type PrimeVueAutoCompleteRefInternalApi = {
	hideOverlay: () => void;
	showOverlay: () => void;
	onKeyDown: (event: Event) => void;
	overlayVisible: boolean;
	inputTextValue: string | null;
	$refs: { input: HTMLInputElement };
	timeout: null | ReturnType<typeof setTimeout>;
	overlay: any | HTMLElement;
	searching: boolean;
};

/**
 * Kopia av funktioner i primevue utils
 */
class PrimevueUtilsDomHandler {
	static findSingle(element: HTMLElement, selector: string) {
		return element.querySelector(selector);
	}

	static index(element: HTMLElement) {
		const children = element.parentNode?.childNodes ?? [];
		let num = 0;
		for (let i = 0; i < children.length; i++) {
			if (children[i] === element) return num;
			if (children[i].nodeType === 1) num++;
		}
		return -1;
	}
}

function useAutocomplete(filters: Filters) {
	const autoCompleteSuggestions: Ref<FilterSuggestion[]> = filters.getSuggestionsRef();
	
	// reactive([]) fungerar inte, använd ej med array.
	// const autoCompleteItems =
	//   filters.getController("MatchText")?.controllerFilters ??
	//   (ref([]) as Ref<FilterItem[]>);

	const autoCompleteItems = computed({
		get: () => filters.controllers.flatMap(c => c.controllerFilters.value),
		/**
		 * Denna fungerar bara för att ta bort!
		 * Det betyder att den är imun mot det som Autocomplete-komponenten lägger till själv.
		 */
		set: arrayWithOneMissing => {
			filters.controllers.forEach(c => {
				c.controllerFilters.value.filter(f => !arrayWithOneMissing.includes(f)).forEach(f => c.removeFilter(f));
			});
		},
	});

	const autoCompleteInvalid = ref(false);

	/**
	 * Vi kopplar in oss i PrimeVue AutoComplete interna API för att kunna förändra beteendet lite.
	 */
	const autoCompleteRef = ref<PrimeVueAutoCompleteRefInternalApi>(undefined as any);

	function getAutomcompleteValue(): string {
		//inputAutoComplete.value = event.value.query;
		//autoCompleteRef.value.inputTextValue = value;
		return autoCompleteRef.value.$refs.input.value;
	}
	function setAutomcompleteValue(value?: string): void {
		//inputAutoComplete.value = event.value.query;
		autoCompleteRef.value.$refs.input.value = value ?? "";
		autoCompleteRef.value.inputTextValue = value ?? "";
	}
	/**
	 * @complete="autoCompleteOnSuggest($event)"
	 */
	function autoCompleteOnSuggest(event: { originalEvent: Event; query: string }) {
		// Ta om samma ord söks direkt igen. (Exempel: skriv en boksta -> sudda snabbt igen )
		// Så uppfattar Automcomplete inte att den är klar och spinnern bara snurrar.
		// Detetektera detta och visa förslagen och stoppa spinnern:
		if (!filters.setQuery(event.query)) {
			if (autoCompleteSuggestions.value.length > 0) {
          autoCompleteRef.value.showOverlay();
			}
			autoCompleteRef.value.searching = false;
		}
	}
	/**
	 * @item-select="autoCompleteOnItemSelect($event)"
	 */
	function autoCompleteOnItemSelect(event: { originalEvent: Event; value: FilterSuggestion }) {
		if (event.value.type === SuggestionType.Link && event.value.link) {
			router.push(event.value.link);
		} else if (!event.value.filter) {
			// Vi är inte klara än...
			setAutomcompleteValue(event.value.query);
		} else {
			// Uppdatera med query?
			filters.processFilterItem(event.value as FilterItem);
		}
	}
	function autoCompleteOnInput(event: InputEvent) {
		autoCompleteInvalid.value = false;
	}
	/**
	 * @keydown="autoCompleteOnKeydown
	 * Den är samma keydown som Autocomplete lyssnar på.
	 * Denna är dock lyckligtvis före i kön. Vi stoppar
	 * vidare propagering till Autocomplete, och anropar
	 * den manuellt från här istället.
	 *
	 * På så sätt kan vi kontrollera om Autocomplete gör något med den eller inte,
	 * genom att följa betendet på overlay.
	 */
	function autoCompleteOnKeydown(event: KeyboardEvent) {
		switch (event.keyCode) {
			case 8: {
				// Backspace
				event.stopImmediatePropagation();
				const currentValue = getAutomcompleteValue();
				if (!currentValue && autoCompleteItems.value.length > 0) {
					const last = autoCompleteItems.value[autoCompleteItems.value.length - 1];
					filters.removeFilter(last);
					setAutomcompleteValue(last!.query);
					event.preventDefault();
					//autoCompleteInvalid.value = true;
				}
				break;
			}
			case 13: // Enter
				event.stopImmediatePropagation();

				// Om ett autocomplete-förslag är valt, så körs det via AutoComplete-komponentens
				// egna kod, som anropar autoCompleteOnItemSelect
				if (autoCompleteRef.value.overlayVisible) {
					autoCompleteRef.value.onKeyDown(event);
					if (!autoCompleteRef.value.overlayVisible) {
						// autoCompleteOnItemSelect är körd, vi gör inte mer!
						return;
					}
				}
				// Annars använder vi det själva för att göra en sökning av det som är inskrivet:
				autoCompleteRef.value.hideOverlay();

				if (autoCompleteRef.value.timeout) {
					clearTimeout(autoCompleteRef.value.timeout);
					autoCompleteRef.value.timeout = null;
				}

				if (filters.parseQuery(getAutomcompleteValue())) {
					setAutomcompleteValue("");
					//     autoCompleteSuggestions.value = filterControllers.reduce(
					// (autoCompleteSuggestions, filterObject) => autoCompleteSuggestions.concat(filterObject.parseQuery(getAutomcompleteValue())),
					// [] as Array<FilterSuggestion>,
					// );
					//inputAutoComplete.value = "";
					// inputAutoComplete.dispatchEvent(new Event("change"));
					// inputAutoComplete.focus();
					//autoCompleteRef.value.hideOverlay();
				} else {
					autoCompleteInvalid.value = true;
				}
				break;
		}
	}
	function autoCompleteOnKeyup(event: KeyboardEvent) {
		switch (event.key) {
			case "ArrowDown":
			case "ArrowUp":
				if (autoCompleteRef.value.overlay) {
					const marked =
						autoCompleteSuggestions.value[
							PrimevueUtilsDomHandler.index(autoCompleteRef.value.overlay.querySelector("li.p-highlight"))
						];
					if (marked) {
						setAutomcompleteValue(marked.query);
					}
				}
		}
	}
	return {
		autoCompleteItems,
		autoCompleteInvalid,
		autoCompleteSuggestions,
		autoCompleteRef,
		autoCompleteOnSuggest,
		autoCompleteOnItemSelect,
		autoCompleteOnKeydown,
		autoCompleteOnKeyup,
		autoCompleteOnInput,
	};
}

export default defineComponent({
	name: "SearchBar",
	components: {
		AutoComplete,
		LengthFilter,
		QueryResult,
		CommonFilter,
	},
	setup() {
		const router = useRouter();
		// Parsa query-parmatern q när sidan laddas:
		searchFilters.parseQueryQ(router.currentRoute.value.query.q as string);

		// Uppdatera hela tiden query-parmatern q med aktuell sökning:
		watch(
			searchFilters.queryQ,
			() => {
				router.replace({
					query: {
						...(searchFilters.queryQ.value ? { q: searchFilters.queryQ.value } : undefined),
					},
				});
				searchStore.setSearchString(searchFilters.queryQ.value);
				// updateSearchStringDebounced();
			},
			{ immediate: true },
		);

		// https://stackoverflow.com/questions/40848348/spread-an-array-of-objects-into-a-parent-object
		return {
			searchFilters,
			lengthFilterController: searchFilters.getController<LengthFilterController>(SuggestionSource.Length),
			codeFilterController: searchFilters.getController<CodeFilterController>(SuggestionSource.Code),
			...useAutocomplete(searchFilters),
			marked,
		};
	},
});
