
import { defineComponent, PropType } from "vue";
import FilterSection from "./FilterSection.vue";
import InputNumber from "primevue/inputnumber";
import Slider from "primevue/slider";
import LengthFilterController from "./LengthFilterController";
import { FilterItem } from ".";

export default defineComponent({
	name: "LengthFilter",
	components: {
		FilterSection,
		InputNumber,
		Slider,
	},
	props: {
		filterController: {
			type: Object as PropType<LengthFilterController>,
			required: true,
		},
	},
	setup(props) {
		function getFilterFromEvent(element: EventTarget | Element | HTMLElement | null): FilterItem | null {
			if (element && "dataset" in element && element.dataset.filter) {
				return JSON.parse(element.dataset.filter) as FilterItem;
			} else if (element && "parentElement" in element) {
				return getFilterFromEvent(element.parentElement);
			}
			return null;
		}

		function onRemove(event: Event) {
			const filter = getFilterFromEvent(event.target);
			if (filter) {
				props.filterController.removeFilter(filter);
				event.target;
			} else {
				console.error("Filter is missing!", filter);
			}
		}
		return {
			...props.filterController.use(),
			onRemove,
		};
	},
});
