import { EntityCodeType, SuggestionSource } from "@/api/generated/graphql-operations";
import { computed, ref, Ref } from "vue";
import { FilterController, FilterItem, FilterSuggestion } from ".";
import ServersideFilterController from "./ServersideFilterController";
import CodeFilterController from "./CodeFilterController";
import LengthFilterController from "./LengthFilterController";
import MatchTextFilterController from "./MatchTextFilterController";

export class Filters {
	public query: Ref<string> = ref("");

	public controllers: FilterController[] = [
		new ServersideFilterController(this.query),
		new LengthFilterController(this.query),
		new CodeFilterController(this.query, "SFF riksnummer", EntityCodeType.Ssf, ["riksnr", "riksnummer"]),
		new MatchTextFilterController(this.query), // låt denna vara sist då den tar det mesta...
	];

	public id: string;
	constructor(id: string) {
		this.id = id;
	}

	public reset() {
		this.controllers.forEach(c => {
			c.reset();
		});
	}
	public parseQuery(query: string) {
		return this.controllers.some(c => c.parseQuery(query));
	}
	public getController<T extends FilterController>(type: SuggestionSource): T {
		return this.controllers.find(c => c.type == type) as T;
	}
	public setQuery(query: string) {
		if (this.query.value !== query) {
			this.query.value = query;
			return true;
		}
		return false;
	}
	public getSuggestionsRef(): Ref<FilterSuggestion[]> {
		return computed(() => {
			return this.controllers.flatMap(c => c.suggestions.value);
		});
	}

	public processFilterItem(filterItem: FilterItem) {
		return this.controllers.some(filterObject => filterObject.processFilterItem(filterItem));
	}
	/**
	 * Aktuell query-parameter
	 * q=...|...|...
	 */
	public queryQ = computed(() =>
		this.controllers
			.flatMap(c => c.controllerFilters.value)
			.map(filterItem => filterItem.query)
			.join("|"),
	);
	/**
	 * Funktion för att parsa query-parameter med aktuell sökning
	 */
	public parseQueryQ(q?: string) {
		if (q) {
			this.reset();
			const parts = q.split("|");
			parts.forEach(qPart => {
				this.parseQuery(qPart);
			});
		}
	}
	public removeFilter(filterItem: FilterItem) {
		this.controllers.find(c => c.type == filterItem.source)?.removeFilter(filterItem);
	}
}

export const searchFilters = new Filters("searchFilters");
