import jwtDecode from "jwt-decode";
import { ApolloClient, createHttpLink, QueryOptions } from "@apollo/client/core";
import { Store, StoreData } from "@/stores/Store";
import { AccessToken, AccessTokenPayload, UserLite } from "@/types";

import {
	LoginDocument,
	LogoutDocument,
	RefreshTokenDocument,
	RefreshTokenQuery,
	RefreshTokenQueryVariables,
} from "@/api/generated/graphql-operations";
import UserUtil from "@/util/UserUtil";
import { cache } from "@/api/ApolloCache";

interface ApiData extends StoreData {
	/**
	 * null:  Ej hämtat accessToken
	 * AccessToken: aktuellt accessToken
	 */
	accessToken: AccessToken | null;
	/**
	 * undefined: Ej hämtat accessToken
	 * null: Ej inloggad
	 * User: inloggad användare
	 */
	currentUser: undefined | null | UserLite;
	groupIds: number[];
	/**
	 * Uppdateras med Date.now() när ändring i inloggning gjorts.
	 */
	loggedInUpdated: number;
}

class Api extends Store<ApiData> {
	protected data(): ApiData {
		return {
			loggedInUpdated: 0,
			currentUser: undefined,
			accessToken: null,
			groupIds: [],
		};
	}
	protected initialized = false;
	protected updateFromPayload = true;
	protected setup(): void {
		// Vi lyssnar på händelser i localStorage för att detektera in och utloggning
		// i andra flikar
		console.log('addEventListener("storage")');
		addEventListener("storage", (event: StorageEvent) => {
			switch (event.key) {
				case "logout":
				case "login":
					// En annan flik har loggat in eller ut.
					// Vi tar bort vårat accessToken för att trigga ett nytt.
					console.log(event.key + " detected2, requesting new accessToken");
					this.processAccessToken(null);
					this.refreshAccessTokenNow();
					break;
			}
		});
	}
	public init() {
		// Anropa servern och be om ett accessToken.
		// (På login-sidan vill vi undvika detta.)
		if (!this.initialized) {
			this.refreshAccessTokenNow();
			this.initialized = true;
		}
	}

	/**
	 * Hämtar en accessToken, baserat på eventuell refreshToken som finns som Cookie.
	 * Samma som i jwtTokenBearerFetch, fast utan att returnera en Promise.
	 * Används vid enskillt anrop, utan att det kedjas med ett vanligt API-anrop.
	 */
	protected refreshAccessTokenNow() {
		// this.initialized = true;
		this.apolloClientLogin.query(this.getRefreshQuery()).then(result => {
			// applicationStore.showMessages(result.data.refreshToken.messages);
			// refreshToken returnerar ett accessToken...
			this.processAccessToken(result.data.refreshToken.jwtToken, result.data.refreshToken.jwtTokenExp);
		});
	}

	/**
	 * Intern funktion för att uppdatera aktuellt accessToken.
	 * Kommer från aktiv inloggnin eller från en jwtTokenBearerFetch i ApiClient (ApolloClient)
	 *
	 * @param accessToken
	 */
	protected processAccessToken(jwtToken: string | null, jwtTokenExpClientAdjusted?: number) {
		if (jwtToken) {
			const payload = jwtDecode<AccessTokenPayload>(jwtToken);
			this.state.accessToken = {
				jwtToken,
				jwtTokenExp: jwtTokenExpClientAdjusted ?? payload.exp,
			};
			this.initialized = true;
			if (
				this.updateFromPayload ||
				this.state.currentUser?.userId !== payload.u?.id ||
				this.state.groupIds.toString() !== (payload.g ?? []).toString()
				// || this.state.locale !== payload.loc
			) {
				this.processAccessTokenPayload(payload);
			}
		} else {
			this.state.accessToken = null;
			// Metoden är anropad med null, vilket görs när in/utloggning gjorts
			// från annan flik. Vi vet ej vad CurrentUser är.
			this.processAccessTokenPayload(undefined);
		}
	}
	protected processAccessTokenPayload(accessTokenPayload?: AccessTokenPayload) {
		// Reseta cachen, så att sökresultat etc blir korrekta.
		// https://www.apollographql.com/docs/react/caching/advanced-topics/
		this.apolloClient.resetStore();

		if (!accessTokenPayload) {
			// Vi saknar accessToken (Inloggning okänd)
			this.state.currentUser = undefined;
			this.state.groupIds = [];
			// Låt locale vara!
		} else if (!accessTokenPayload.u) {
			//User i accessToken är null (Ej inloggad)
			this.state.currentUser = null;
			this.state.groupIds = [];
			// this.state.locale = accessTokenPayload.loc;
		} else {
			this.state.currentUser = UserUtil.createUserLiteFromTokenPayload(accessTokenPayload.u);
			this.state.groupIds = accessTokenPayload.g ?? [];
			// this.state.locale = accessTokenPayload.loc;
		}
		this.updateFromPayload = false;
		this.state.loggedInUpdated = Date.now();
	}
	// #######################################

	/**
	 * Aktiv inloggning i denna flik med en login-JWT!
	 * Anropas från någon komponent
	 * @param string loginJwt
	 */
	public login(loginJwt: string): Promise<boolean> {
		return this.apolloClientLogin
			.mutate({
				mutation: LoginDocument,
				variables: {
					loginJwt,
					time: Math.floor(Date.now() / 1000),
				},
			})
			.then(result => {
				// applicationStore.showMessages(result.data.login.messages);
				this.processAccessToken(result.data!.login.jwtToken, result.data!.login.jwtTokenExp);
				// Broadcast försök till inloggning till alla flikar
				localStorage.setItem("login", Date.now().toString());
				return Boolean(this.state.currentUser);
			});
	}
	/**
	 * Aktiv utloggning i denna flik!
	 * Anropas från någon komponent.
	 * Anropet ger en ny accessToken med en anonym användare.
	 */
	public logout() {
		return this.apolloClientLogin
			.mutate({
				mutation: LogoutDocument,
				variables: {
					time: Math.floor(Date.now() / 1000),
				},
			})
			.then(result => {
				this.processAccessToken(result.data!.logout.jwtToken, result.data!.logout.jwtTokenExp);
				// Broadcast utloggning till alla flikar
				localStorage.setItem("logout", Date.now().toString());
				return true;
			})
			.catch((/*ex*/) => {
				console.error("Misslyckades med att logga ut");
				return false;
			});
	}
	protected isAccessTokenValid(): boolean {
		return this.state.accessToken != null && (this.state.accessToken.jwtTokenExp - 15) * 1000 > Date.now();
	}
	/**
	 * Intern återanvändningsbar GraphQL-Query för ApolloClient.
	 */
	protected getRefreshQuery(): QueryOptions<RefreshTokenQueryVariables, RefreshTokenQuery> {
		return {
			query: RefreshTokenDocument,
			variables: {
				time: Math.floor(Date.now() / 1000),
			},
			// https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
			fetchPolicy: "no-cache",
		};
	}

	/**
	 * fetch-funktion för apolloClient
	 * Kontrollerar om vi har ett aktuellt accessToken, om inte så förnyas detta först,
	 * innan riktiga anropet görs.
	 * @param uri
	 * @param options
	 */
	protected jwtTokenBearerFetch: (uri: RequestInfo, options: RequestInit) => Promise<Response> = (
		uri: RequestInfo,
		options: RequestInit,
	) => {
		// this.initialized = true;
		if (!this.isAccessTokenValid()) {
			// Vi måste förnya vår accessToken
			// Detta gör vi med en client som inte använder denna jwtTokenBearerFetch
			return this.apolloClientLogin
				.query(this.getRefreshQuery())
				.then(result => {
					// applicationStore.showMessages(result.data.refreshToken.messages);
					console.log("Access token refreshed.");
					// refreshToken returnerar ett accessToken...
					this.processAccessToken(result.data.refreshToken.jwtToken, result.data.refreshToken.jwtTokenExp);
					return this.jwtTokenBearerFetchWithToken(uri, options);
				})
				.catch(ex => {
					// 	console.log("Fel vid token refresh");
					// 	console.log(ex);
					// 	// if (ex.constructor.name == ApolloError.name) {
					// 	// 	const error: ApolloError = ex;
					// 	// 	//error.message
					// 	// 	this.apiState.error = error;
					// 	// }
					// 	// this.apiState.loading = false;
					return Promise.reject(ex);
				});
		} else {
			return this.jwtTokenBearerFetchWithToken(uri, options);
		}
		//return Promise.reject(new Error('Ej inloggad'));
	};
	/**
	 * Del av {@link jwtTokenBearerFetch}
	 * @param uri
	 * @param options
	 */
	protected jwtTokenBearerFetchWithToken: (uri: RequestInfo, options: RequestInit) => Promise<Response> = (
		uri: RequestInfo,
		options: RequestInit,
	) => {
		const accessToken = this.state.accessToken;
		if (accessToken) {
			options.headers = {
				...options.headers,
				authorization: `Bearer ${accessToken.jwtToken}`,
			};
		}
		return fetch(uri, options);
	};
	protected httpLinkLogin = createHttpLink({
		uri: process.env.VUE_APP_API_URL,
		// För att kunna skicka med  refreshToken som cookies:
		credentials: "include",
	});
	/**
	 * API-Client som inte använder utan authorization: Bearer-headern
	 * ApolloClient<NormalizedCacheObject>
	 */
	protected apolloClientLogin = new ApolloClient({
		link: this.httpLinkLogin,
		connectToDevTools: true,
		uri: process.env.VUE_APP_API_URL,
		cache,
	});
	protected httpLink = createHttpLink({
		uri: process.env.VUE_APP_API_URL,
		// För att skicka med accessToken
		fetch: this.jwtTokenBearerFetch,
	});
	/**
	 * API-Client som använder har authorization: Bearer-headern
	 * satt med en aktuell access-token
	 * ApolloClient<NormalizedCacheObject>
	 */
	public apolloClient = new ApolloClient({
		link: this.httpLink,
		connectToDevTools: true,
		uri: process.env.VUE_APP_API_URL,
		cache,
	});
}

export const api = new Api();
export const apolloClient = api.apolloClient;

export function useApi() {
	return {
		apiData: api.getState(),
	};
}
