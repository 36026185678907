export class LogScaleUtil {
	private A: number;
	private B: number;
	private C: number;
	private integerLimit: number;
	private lowerSlider: number;
	private upperSlider: number;
	public constructor(
		lowerSlider: number,
		upperSlider: number,
		minValue: number,
		midValue: number,
		maxValue: number,
		integerLimit: number,
	) {
		this.lowerSlider = lowerSlider;
		this.upperSlider = upperSlider;
		const x = minValue,
			y = midValue,
			z = maxValue;

		this.A = (x * z - y * y) / (x - 2 * y + z);
		this.B = (y - x) ** 2 / (x - 2 * y + z);
		this.C = 2 * Math.log((z - y) / (y - x));

		this.integerLimit = integerLimit;
	}
	//https://stackoverflow.com/questions/7246622/how-to-create-a-slider-with-a-non-linear-scale
	public fromSlider(sliderValue: number) {
		const sliderValueNormal = (sliderValue - this.lowerSlider) / (this.upperSlider - this.lowerSlider);
		// console.log(`sliderValue: ${sliderValue}, lowerSlider: ${this.lowerSlider}, upperSlider: ${this.upperSlider}`);
		// console.log(sliderValueNormal);
		const re = this.A + this.B * Math.exp(this.C * sliderValueNormal);
		return re > this.integerLimit ? Math.round(re) : Math.round(re * 10) / 10;
	}
	public toSliderValue(value: number) {
		return (Math.log((value - this.A) / this.B) / this.C) * (this.upperSlider - this.lowerSlider) + this.lowerSlider;
	}
}
