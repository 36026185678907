
import { defineComponent, watch } from "vue";
import { Filters } from "./filter/Filters";
import QueryResult from "./QueryResult.vue";

export default defineComponent({
	name: "Query",
	components: {
		QueryResult,
	},
	props: {
		query: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const filters = new Filters("query");
		watch(
			props,
			() => {
				filters.parseQueryQ(props.query);
			},
			{
				immediate: true,
			},
		);

		return {
			filters,
		};
	},
});
