import { watch, ref, computed, WritableComputedRef, Ref } from "vue";
import { FilterController, FilterItem, FilterSuggestion } from ".";
import { LogScaleUtil } from "@/util/LogScaleUtil";
import { InputEntitySearchFilter, SuggestionSource, SuggestionType } from "@/api/generated/graphql-operations";

export default class LengthFilterController extends FilterController {
	public label = "Grottlängd";
	public reset() {
		this.lengthRangeDisplayFrom.value = 0;
		this.lengthRangeDisplayTo.value = this.lengthMax;
		this.filterMap = {};
		this.updateFilters();
	}
	public type = SuggestionSource.Length;
	public lengthSliderSteps = 40;
	public lengthMax = 6300;
	public lengthRange = ref([0, this.lengthSliderSteps]);
	private logScale = new LogScaleUtil(
		1, // 0% (Observera att även 0, finns som används för nullmarkering för lägre gräns)
		this.lengthSliderSteps, // 100%
		1, // 0% på slidern 1 meter
		100, // 50% på slidern är 100 m
		this.lengthMax, // 100% på slidern är ...
		25, // Under 25 tillåter vi en decimal.
	);
	public outputFilters = this.controllerFilters;
	/**
	 * Värdet som används i InputNumber för Grottlängd från:
	 * Tomt fält är null
	 */
	public lengthRangeDisplayFrom: WritableComputedRef<number | null> = computed({
		get: () => (this.lengthRange.value[0] < 1 ? null : this.logScale.fromSlider(this.lengthRange.value[0])),
		set: value => {
			this.lengthRange.value[0] = value && value >= 1 ? this.logScale.toSliderValue(value) : 0;
		},
	});
	/**
	 * Värdet som används i InputNumber för Grottlängd upp till:
	 */
	public lengthRangeDisplayTo: WritableComputedRef<number> = computed({
		get: () => this.logScale.fromSlider(this.lengthRange.value[1]),
		set: value => {
			this.lengthRange.value[1] = this.logScale.toSliderValue(value);
		},
	});

	/**
	 * Internt håller vi reda på filterna med comparator som nyckel,
	 * då det inte är någon mening att ha samma två gånger.
	 */
	private filterMap: Record<string, FilterItem> = {};

	constructor(query: Ref<string>) {
		super(query);
		watch(
			this.lengthRangeDisplayFrom,
			() => {
				if (this.lengthRangeDisplayFrom.value) {
					this.setFilter(
						this.createSuggestion("length", false, ">=", this.lengthRangeDisplayFrom.value.toString()) as FilterItem,
					);
				} else {
					this.removeFilterType(">");
				}
				this.updateFilters();
			},
			{
				// "pre" är default, vilket inte fungerade med reset, vilket gav en glitch andra gången samma söksida laddades.
				flush: "sync",
			},
		);
		watch(
			this.lengthRangeDisplayTo,
			() => {
				if (this.lengthRangeDisplayTo.value != this.lengthMax) {
					this.setFilter(
						this.createSuggestion("length", true, "<=", this.lengthRangeDisplayTo.value.toString()) as FilterItem,
					);
				} else {
					this.removeFilterType("<");
				}
				this.updateFilters();
			},
			{
				flush: "sync",
			},
		);
	}
	public removeFilter(filterItem: FilterItem) {
		const filterType = this.getFilterType(filterItem);
		switch (filterType) {
			case ">":
				this.lengthRangeDisplayFrom.value = 0;
				break;
			case "<":
				this.lengthRangeDisplayTo.value = this.lengthMax;
				break;
			default:
				this.removeFilterType(this.getFilterType(filterItem));
		}
	}
	private removeFilterType(key: string) {
		delete this.filterMap[key];
		this.updateFilters();
	}
	private setFilter(filterItem: FilterItem) {
		this.filterMap[this.getFilterType(filterItem)] = filterItem;
		this.updateFilters();
	}
	private getFilterType(filterItem: FilterItem): string {
		switch (filterItem.filter.comparator) {
			case ">":
			case ">=":
				return ">";
			case "<":
			case "<=":
				return "<";
			default:
				return filterItem.filter.comparator ?? "";
		}
	}

	private updateFilters() {
		// Sortering är för att ha > innan <, övvrig ordning inte testad.
		this.controllerFilters.value.splice(
			0,
			this.controllerFilters.value.length,
			...Object.values(this.filterMap).sort(
				(a, b) => -(a.filter.comparator ?? "").localeCompare(b.filter.comparator ?? ""),
			),
		);
	}
	public parseQuery(query: string): boolean {
		const filterItem = this.suggest(query, true).find(suggestion => suggestion.filter) as FilterItem | undefined;
		if (filterItem) {
			return this.processFilterItem(filterItem);
		}
		return false;
	}
	public processFilterItem(filterItem: FilterItem): boolean {
		if (filterItem.type === SuggestionType.Length) {
			filterItem = {
				...filterItem,
				source: SuggestionSource.Length,
			};
			// Om det är matchar from eller to, så uppdaterar vi gränssnittet,
			// som i sin tur sätter filtret.
			switch (filterItem.metadata?.gui) {
				case "from":
					this.lengthRangeDisplayFrom.value = +filterItem.filter.value!;
					break;
				case "to":
					this.lengthRangeDisplayTo.value = +filterItem.filter.value!;
					break;
				default:
					this.setFilter(filterItem as FilterItem);
			}
			return true;
		}
		return false;
	}
	public suggest(query: string, strict = false): Array<FilterSuggestion> {
		const re: Array<FilterSuggestion> = [];

		let command: string | undefined;
		if (!strict && (query.length == 0 || /^\d+$/.test(query))) {
			// Om tom sträng eller bara ett tal:
			command = "längd";
		} else {
			[command] = query.match(/^[a-zA-ZåäöÅÄÖ_]+/) ?? [undefined];
		}

		if (command && command.length > 0) {
			const keyword = ["längd", "length", "len"].find(keyWord => keyWord.startsWith(command!.toLowerCase()));
			if (keyword) {
				let [
					,
					,
					// parameters[0] Hela
					// parameters[1] kommando (length|len|längd) keyword, då detta kan vara partciellt
					includeNull, // parameters[2] include null (!|?|undefined)
					comparator, // parameters[3] comparator (>=|<=|=|!=|<|>|undefined)
					// eslint-disable-next-line prefer-const
					value, // parameters[4] value (number as string|undefined)
				] =
					query.match(
						/^(le?n?g?t?h?|lä?n?g?d?|le?n?)?\s*(\?)?\s*(!|<|<=|=|>=|>|!=|=!|<>|=<|=>)?\s*(\d+(\.\d+)?)?\s*$/,
					) ?? new Array(5).fill(undefined);
				includeNull = includeNull == "?";
				switch (comparator) {
					case "=<":
						comparator = "<=";
						break;
					case "=>":
						comparator = ">=";
						break;
					case "=!":
					case "<>":
						comparator = "!=";
						break;
				}
				// Ska vi föreslå "from" utfrån detta?
				if (!includeNull && (comparator == undefined || comparator == ">=" || (!strict && comparator == ">"))) {
					// Förslag för from:
					re.push(this.createSuggestion(keyword, false, ">=", value, { gui: "from" }));
				}
				// Ska vi föreslå "to" utfrån detta?
				if (
					(includeNull || !strict) &&
					(comparator == undefined || comparator == "<=" || (!strict && comparator == "<"))
				) {
					// Förslag för to:
					re.push(this.createSuggestion(keyword, true, "<=", value, { gui: "to" }));
				}
				if (re.length == 0) {
					// Ge alltid ett förslag om alla parametrar finns med.
					re.push(this.createSuggestion(keyword, includeNull, comparator, value));
				}
			}
		}

		return re;
	}

	private createSuggestion(
		keyword: string,
		includeNull: boolean,
		comparator: string,
		value?: string,
		metadata?: Record<string, any>,
	): FilterSuggestion {
		let filter: InputEntitySearchFilter | undefined = undefined;
		if (value || comparator == "!" || (includeNull && comparator == undefined)) {
			// Informationen är komplett, vi kan skapa ett filter:
			filter = {
				field: "length",
				...(includeNull ? { includeNull } : undefined),
				...(comparator ? { comparator } : undefined),
				...(!(comparator == "!" || (includeNull && comparator == undefined)) ? { value } : undefined),
			};
		}
		// Vi visar bara info om nullhantering om det inte känns naturligt:
		let nullInfo = "";
		if (includeNull && (comparator.includes(">") || comparator == "=" || comparator == "!")) {
			nullInfo = " Visa även de utan värde.";
		} else if (!includeNull && (comparator.includes("<") || comparator == "!=")) {
			nullInfo = " Visa inte de utan värde.";
		}
		const valueInfo = comparator == "!" ? "" : ` ${value ?? "…"} meter`;
		return {
			source: this.type,
			type: SuggestionType.Length,
			label: "Grottlängd",
			desc: `${this.comparator2text(comparator, includeNull)}${valueInfo}.${nullInfo}`,
			query: `${keyword}${includeNull ? "?" : ""}${comparator}${comparator != "!" ? value ?? "" : ""}`,
			filter,
			...(metadata ? { metadata } : undefined),
		};
	}
	protected comparator2text(comparator: string, includeNull: boolean): string {
		switch (comparator) {
			case "<=":
				return "Upp till";
			case "<":
				return "Kortare än";
			case ">=":
				return "Minst";
			case ">":
				return "Längre än";
			case "=":
				return "Exakt";
			case "!=":
				return "Ej";
			case "!":
				return "Angiven";
			case undefined:
				if (includeNull) {
					return "Saknas";
				}
			// falls through
			default:
				return "(okänd komparator)";
		}
	}
	public use() {
		return {
			lengthRange: this.lengthRange,
			lengthRangeDisplayTo: this.lengthRangeDisplayTo,
			lengthRangeDisplayFrom: this.lengthRangeDisplayFrom,
		};
	}
}
