import { Suggestion, EntitySearchFilter, SuggestionSource } from "@/api/generated/graphql-operations";
import { ref, Ref, watch } from "vue";

/**
 * Detta är typen på classen som skapar suggestions, och används för .source i dessa.
 * Autocomplete kan skapa filter av andra typer.
 */
//export type FilterControllerTypes = "Length" | "MatchText" | "Code" | "Serverside";
/**
 * Detta är typen av filter.
 */
//export type FilterTypes = "Length" | "MatchText" | "Code" | "Link";
//typeof SuggestionSource[keyof typeof SuggestionSource] =

export abstract class FilterController {
	public label: null | string =  null;
	
	abstract type: SuggestionSource;
	abstract suggest(query: string, strict?: boolean): Array<FilterSuggestion>;

	public suggestions: Ref<Array<FilterSuggestion>> = ref([]);
	public controllerFilters: Ref<FilterItem[]> = ref([]);
	abstract outputFilters: Ref<FilterItem[]>;

	protected query: Ref<string>;

	constructor(query: Ref<string>) {
		this.query = query;
		watch(this.query, async () => {
			this.suggestions.value = await this.suggest(this.query.value);
		});
	}

	abstract processFilterItem(filterItem: FilterItem): boolean;

	abstract reset(): void;
	abstract removeFilter(filterItem: FilterItem): void;

	public parseQuery(query: string): boolean {
		const suggestions = this.suggest(query, true);
		const filterItem = suggestions.find(suggestion => suggestion.filter) as FilterItem | undefined;
		if (filterItem) {
			return this.processFilterItem(filterItem);
		}
		return false;
	}
}

export type FilterSuggestion = Suggestion & {
	metadata?: Record<string, any>;
};

export type FilterItem = Partial<FilterSuggestion> & { filter: EntitySearchFilter };
