import { apolloClient } from "@/api/Api";
import { createApp } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";

// PrimeVue
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import Button from "primevue/button";
import Card from "primevue/card";
import Chip from "primevue/chip";
import Panel from "primevue/panel";

import Tooltip from "primevue/tooltip";

//import "primevue/resources/themes/vela-orange/theme.css"; //theme
import "./assets/styles/global.scss";
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

const vueApp = createApp(App)
	// Här kan även fler klienter anges: https://v4.apollo.vuejs.org/guide-composable/setup.html#multiple-clients
	.provide(DefaultApolloClient, apolloClient)
	// PrimeVue
	.use(PrimeVue)
	.use(ToastService) //https://primefaces.org/primevue/showcase/#/toast
	// PrimeVue - Globala komponenter
	.component("Button", Button)
	.component("Card", Card)
	.component("Chip", Chip)
	.component("Panel", Panel)
	// component("Dropdown", Dropdown)
	// component("InputText", InputText)
	// component("Rating", Rating)
	// component("Skeleton", Skeleton)
	// component("DeferredContent", DeferredContent)
	.directive("tooltip", Tooltip)
	.use(router);

	// Google analytics:
	if (process.env.VUE_APP_GA_MEASUREMENT_ID) {
		vueApp.use(VueGtag as any, {
			config: {
				id: process.env.VUE_APP_GA_MEASUREMENT_ID
			}
		}, router);	
	}

	vueApp.mount("#app");
