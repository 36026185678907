
import { defineComponent } from "vue";
import { api } from "@/api/Api";
import { useToast } from "primevue/usetoast";
import UserUtil from "@/util/UserUtil";
import jwtDecode from "jwt-decode";
import { LoginTokenPayload } from "@/types";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "Login",
	props: {
		jwt: {
			type: String,
			default: null,
		},
	},
	setup(props) {
		const toast = useToast();
		const router = useRouter();
		if (props.jwt) {
			api
				.login(props.jwt)
				.then(successfulLogin => {
					const currentUser = api.getState().currentUser;
					if (successfulLogin && currentUser) {
						const loginPayload = jwtDecode<LoginTokenPayload>(props.jwt);
						console.log(`${UserUtil.fullName(currentUser)} har loggat in.`);
						toast.add({
							severity: "success",
							summary: `Lyckad inloggning från ${loginPayload.iss}`,
							detail: `${UserUtil.fullName(currentUser)} har loggat in.`,
							life: 4000,
						});
						if (loginPayload.referer) {
							router.replace(loginPayload.referer);
						}
					} else {
						router.replace("/");
					}
					//
				})
				.catch
				//
				();
		}

		return {};
	},
});
