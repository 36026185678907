
import { defineComponent } from "vue";
import Layout from "@/layouts/Layout.vue";
import Toast from "primevue/toast";
import { provideApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./api/Api";

export default defineComponent({
	components: {
		Toast,
		Layout,
	},
	setup() {
		//provide(DefaultApolloClient, apolloClient);
		// Denna behövs för att kunna använda useQuery utanför komponenter.
		provideApolloClient(apolloClient);
	},
});
