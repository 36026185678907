import { InMemoryCache } from "@apollo/client/core";
import { TypedTypePolicies } from "./generated/apollo-helpers";

const typePolicies: TypedTypePolicies = {
	// Keys in this object will be validated against the typed on your schema
	User: {
		keyFields: ["userId"],
	},
};

export const cache = new InMemoryCache({ typePolicies });
