
import { defineComponent, watch, PropType, ref } from "vue";
import { FilterController } from ".";

export default defineComponent({
	name: "FilterSection",
	props: {
		filterController: {
			type: Object as PropType<FilterController>,
			required: true,
		},
	},
	setup(props) {
		const filterSectionToggleable = ref(true);
		const filterSectionCollapsed = ref(true);

		// Watch kan både befaka en ref eller ett värde.
		// I detta fall är det samma array-instans som ändras,
		// men det fungerar om vi bevakar värdet.
		watch(
			props.filterController.controllerFilters.value,
			() => {
				// Om filtret inte är tomt, visar vi alltid filtret!
				if (props.filterController.controllerFilters.value.length > 0) {
					filterSectionCollapsed.value = false;
					filterSectionToggleable.value = false;
				} else {
					filterSectionToggleable.value = true;
				}
			},
			{ immediate: true },
		);

		function onHeaderClick() {
			filterSectionCollapsed.value = !filterSectionCollapsed.value && filterSectionToggleable.value;
		}
		return {
			filterSectionToggleable,
			filterSectionCollapsed,
			filterSectionTitle: props.filterController.label ?? props.filterController.type,
			onHeaderClick,
		};
	},
});
