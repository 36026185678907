
import { defineComponent, computed } from "vue";
import marked from "marked";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "Markdown",
	props: {
		markdown: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const router = useRouter();
		const html = computed(() => marked(props.markdown));

		function getHTMLLinkElement(node: EventTarget | Element | null): HTMLLinkElement | null {
			if (node && "tagName" in node && node.tagName == "A") {
				return node as HTMLLinkElement;
			} else if (node && "parentElement" in node) {
				return getHTMLLinkElement(node.parentElement);
			}
			return null;
		}
		// https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
		function onClick(event: MouseEvent) {
			// ensure we use the link, in case the click has been received by a subelement
			const target = getHTMLLinkElement(event.target);
			// handle only links that occur inside the component and do not reference external resources
			if (target && target.matches(".markdown a:not([href*='://'])") && target.href) {
				// some sanity checks taken from vue-router:
				// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
				const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event;
				// don't handle with control keys
				if (metaKey || altKey || ctrlKey || shiftKey) {
					return;
				}
				// don't handle when preventDefault called
				if (defaultPrevented) {
					return;
				}
				// don't handle right clicks
				if (button !== undefined && button !== 0) {
					return;
				}
				// don't handle if `target="_blank"`
				if (target && target.getAttribute) {
					const linkTarget = target.getAttribute("target");
					if (linkTarget && /\b_blank\b/i.test(linkTarget)) {
						return;
					}
				}
				// don't handle same page links/anchors
				const url = new URL(target.href);
				const to = url.pathname;
				if (window.location.pathname !== to && event.preventDefault) {
					event.preventDefault();
					router.push(to);
				}
			}
		}

		return { html, onClick };
	},
});
