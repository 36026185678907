import { SuggestionSource, SuggestionType } from "@/api/generated/graphql-operations";
import { computed, ComputedRef, Ref, ref } from "vue";
import { FilterController, FilterItem, FilterSuggestion } from ".";

export default class MatchTextFilterController extends FilterController {
	public removeFilter(filterItem: FilterItem): void {
		this.controllerFilters.value = this.controllerFilters.value.filter(item => item.query != filterItem.query);
	}
	public reset() {
		this.controllerFilters.value.splice(0);
	}
	public type = SuggestionSource.MatchText;

	public outputFilters: ComputedRef<FilterItem[]> = computed(() => {
		if (this.controllerFilters.value.length > 0) {
			return [
				{
					source: this.type,
					type: "MatchText",
					filter: {
						field: "match-all",
						value: this.controllerFilters.value.map(filterItem => filterItem.filter.value).join(","),
					},
				} as FilterItem,
			];
		}
		return [];
	});

	public processFilterItem(filterItem: FilterItem): boolean {
		if (filterItem.type === SuggestionType.MatchText) {
			filterItem = {
				...filterItem,
				source: SuggestionSource.MatchText,
			};
			this.controllerFilters.value.push(filterItem as FilterItem);
			return true;
		}
		return false;
	}
	public suggest(query: string, strict?: boolean) {
		const re: Array<FilterSuggestion> = [];
		{
			const words = query.matchAll(/([+-]?)([\w\dàáåÅäÄéÉôöÖüÜ]+)(\*?)/g);
			const wordPrefixAll = [];
			const filterAll = [];
			for (const [, plusMinus, wordPrefix, star] of words) {
				wordPrefixAll.push((plusMinus !== "-" ? "" : "-") + wordPrefix);
				filterAll.push((plusMinus !== "-" ? "+" : "-") + wordPrefix + "*");
			}
			if (wordPrefixAll.length > 0) {
				re.push({
					source: this.type,
					type: SuggestionType.MatchText,
					label: "Fritextsökning",
					desc: `Börjar på …`,
					query: wordPrefixAll.join(" "),
					filter: {
						field: "match-all",
						value: filterAll.join(","),
					},
				});
			}
		}

		const [, plusMinus, quote, phrase] = (
			query.match(/^\s*([+-]?)\s*("?)\s*([\wåÅäÄöÖéÉ]{3,}(\s+[\wåÅäÄöÖéÉ]+)*)\s*"?$/) ?? new Array(4).fill(undefined)
		).map((match, index) => {
			switch (index) {
				case 1:
					return match == "-" ? "-" : "";
				case 3:
					if (typeof match === "string") {
						return match.replace(/\s+/g, " ").trim();
					}
				// Falls through
				default:
					return match;
			}
		});

		if (phrase && (quote || !strict)) {
			re.push({
				source: this.type,
				type: SuggestionType.MatchText,
				label: "Fritextsökning",
				desc: plusMinus != "-" ? `Frasen "${phrase}"` : `Ej frasen "${phrase}"`,
				query: `${plusMinus}"${phrase}"`,
				filter: {
					field: "match-all",
					value: `${plusMinus != "-" ? "+" : "-"}"${phrase}"`,
				},
			});
		}

		return re;
	}
}
