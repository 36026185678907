import { EntityCodeType, SuggestionSource, SuggestionType } from "@/api/generated/graphql-operations";
import { computed, ComputedRef, Ref } from "vue";
import { FilterController, FilterItem, FilterSuggestion } from ".";

export default class CodeFilterController extends FilterController {
	public removeFilter(filterItem: FilterItem): void {
		this.controllerFilters.value = this.controllerFilters.value.filter(item => item.query != filterItem.query);
	}
	public type = SuggestionSource.Code;
	protected entityCodeType: EntityCodeType;
	protected codeTypeSlug: Lowercase<EntityCodeType>;
	protected keywords: string[];
	constructor(query: Ref<string>, label: string, entityCodeType: EntityCodeType, extraKeywords: string[] = []) {
		super(query);
		this.label = label;
		this.entityCodeType = entityCodeType;
		this.codeTypeSlug = entityCodeType.toLowerCase() as Lowercase<EntityCodeType>;
		this.keywords = [this.codeTypeSlug, ...extraKeywords];
	}
	public reset() {
		this.controllerFilters.value.splice(0);
	}

	public outputFilters: ComputedRef<FilterItem[]> = computed(() => {
		if (this.controllerFilters.value.length > 0) {
			return [
				{
					source: this.type,
					type: SuggestionType.Code,
					label: this.label,
					filter: {
						field: "code_" + this.codeTypeSlug,
						comparator: "IN",
						value: this.controllerFilters.value.map(filterItem => filterItem.filter.value).join(","),
					},
				},
			];
		}
		return [];
	});

	public processFilterItem(filterItem: FilterItem): boolean {
		if (filterItem.type === SuggestionType.Code) {
			filterItem = {
				...filterItem,
				source: SuggestionSource.Code,
			};
			this.controllerFilters.value.push(filterItem as FilterItem);
			return true;
		}
		return false;
	}
	public suggest(query: string, strict?: boolean) {
		const re: Array<FilterSuggestion> = [];

		if (strict) {
			const regexp = new RegExp(`^${this.codeTypeSlug}:(\\d+(,\\d+)*)$`);
			const [, value] = query.match(regexp) ?? [undefined, undefined];
			if (value) {
				re.push({
					source: this.type,
					type: SuggestionType.Code,
					label: this.label,
					query,
					filter: {
						field: "code_" + this.codeTypeSlug,
						value,
					},
				});
			}
		} else {
			let command: string | undefined;
			let noCommandGiven = false;
			if (/^(\d+[\s,]*)+$/.test(query) || query === "") {
				// Bara ett tal:
				command = this.codeTypeSlug;
				noCommandGiven = true;
			} else {
				[command] = query.match(/^[a-zA-ZåäöÅÄÖ_]+/) ?? [undefined];
			}
			if (command && command.length > 0) {
				const keyword = this.keywords.find(keyWord => keyWord.startsWith(command!.toLowerCase()));
				if (keyword) {
					// Parsa  alla nummer oavsett annat skräp, eller vad som används för delimeter:
					const value = [...query.matchAll(/\d+/g)].flat().join(",");
					re.push({
						source: this.type,
						type: SuggestionType.Code,
						label: this.label,
						query: `${keyword}:${value}`,
						...(value
							? {
									filter: {
										field: "code_" + this.codeTypeSlug,
										value,
									},
							  }
							: undefined),
					});
					// Visa att det går att ange flera:
					if (!noCommandGiven && value.length > 0) {
						re.push({
							source: this.type,
							type: SuggestionType.Code,
							label: this.label,
							desc: `Fler nummer (${value}, … )`,
							query: `${keyword}:${value},`,
						});
					}
				}
			}
		}
		return re;
	}
}
