<template>
	
	Zoom: {{ zoom }}

	<div style="height: 75vh; width: 50vw;">
		<LMap v-model="zoom" v-model:zoom="zoom" :center="[47.41322, -1.219482]" @move="log('move')">
			<LTileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution="© OpenStreetMaps bidragsgivare"
			></LTileLayer>
			<!--<Lwms-tile-layer
        baseUrl="http://ows.mundialis.de/services/service?"
        layers="TOPO-OSM-WMS"
      ></Lwms-tile-layer>-->
			<LWmsTileLayer
				baseUrl="https://maps3.sgu.se/geoserver/berg/ows?"
				layers="SE.GOV.SGU.BERG.KARTERINGSOMRADE.50K,SE.GOV.SGU.BERG.GEOLOGISK_ENHET.YTA.50K"
				attribution="Sveriges geologiska undersökning, BERGGRUND 1:50 000-1:250 000"
				format="image/png"
				transparent
				opacity=".5"
			></LWmsTileLayer>
			<LControlLayers />
			<!--
      <LMarker :lat-lng="[0, 0]" draggable @moveend="log('moveend')">
        <LTooltip>
          lol
        </LTooltip>
      </LMarker>

      <LMarker :lat-lng="[67.50, 20.30]">
        <LIcon :icon-url="iconUrl" :icon-size="iconSize" />
      </LMarker>

      <LMarker :lat-lng="[67.50, 20.30]" draggable @moveend="log('moveend')">
        <LPopup>
          lol
        </LPopup>
      </LMarker>
      <LPolyline
        :lat-lngs="[
          [47.334852, -1.509485],
          [47.342596, -1.328731],
          [47.241487, -1.190568],
          [47.234787, -1.358337],
        ]"
        color="green"
      ></LPolyline>
      <LPolygon
        :lat-lngs="[
          [46.334852, -1.509485],
          [46.342596, -1.328731],
          [46.241487, -1.190568],
          [46.234787, -1.358337],
        ]"
        color="#41b782"
        :fill="true"
        :fillOpacity="0.5"
        fillColor="#41b782"
      />
      <LRectangle
        :lat-lngs="[
          [46.334852, -1.509485],
          [46.342596, -1.328731],
          [46.241487, -1.190568],
          [46.234787, -1.358337],
        ]"
        :fill="true"
        color="#35495d"
      />
      <LRectangle
        :bounds="[
          [46.334852, -1.190568],
          [46.241487, -1.090357],
        ]"
      >
        <LPopup>
          lol
        </LPopup>
      </LRectangle>
      -->
		</LMap>
		<!--<button @click="changeIcon">New kitten icon</button>-->
	</div>
</template>
<script>
// import {
// 	LMap,
// 	LIcon,
// 	LTileLayer,
// 	LWmsTileLayer,
// 	LMarker,
// 	LControlLayers,
// 	LTooltip,
// 	LPopup,
// 	LPolyline,
// 	LPolygon,
// 	LRectangle,
// } from "@vue-leaflet/vue-leaflet";

// TODO: Fix, trasig med webpack
//import "leaflet/dist/leaflet.css";

export default {
	components: {
		// LMap,
		// // LIcon,
		// LTileLayer,
		// LWmsTileLayer,
		// // LMarker,
		// LControlLayers,
		// // LTooltip,
		// // LPopup,
		// // LPolyline,
		// // LPolygon,
		// // LRectangle,
	},
	data() {
		return {
			zoom: 2,
			iconWidth: 25,
			iconHeight: 40,
		};
	},
	computed: {
		iconUrl() {
			return `https://placekitten.com/${this.iconWidth}/${this.iconHeight}`;
		},
		iconSize() {
			return [this.iconWidth, this.iconHeight];
		},
	},
	methods: {
		log(a) {
			console.log(a);
		},
		changeIcon() {
			this.iconWidth += 2;
			if (this.iconWidth > this.iconHeight) {
				this.iconWidth = Math.floor(this.iconHeight / 2);
			}
		},
	},
};
</script>
