import {
	AutocompleteQuery,
	AutocompleteQueryVariables,
	AutocompleteDocument,
	Suggestion,
	SuggestionSource,
	SuggestionType,
} from "@/api/generated/graphql-operations";
import { useQuery, useResult } from "@vue/apollo-composable";
import { Ref, ref, watch } from "vue-demi";
import { FilterController, FilterItem, FilterSuggestion } from ".";

export default class ServersideFilterController extends FilterController {
	public type = SuggestionSource.Serverside;

	constructor(query: Ref<string>) {
		super(query);
		const { result } = useQuery<AutocompleteQuery, AutocompleteQueryVariables>(
			AutocompleteDocument,
			() => ({
				startOfWord: this.query.value,
			}),
			() => ({
				enabled: !!this.query.value && this.query.value.length > 1,
			}),
		);
		const autocompleteResult = useResult(result, [] as Suggestion[], r => r.autocomplete);
		watch(autocompleteResult, () => {
			if (autocompleteResult.value.length > 0) {
				this.suggestions.value = [...autocompleteResult.value];
			}
		});
	}
	public suggest(query: string, strict?: boolean): FilterSuggestion[] {
		return [];
	}
	public processFilterItem(filterItem: FilterItem): boolean {
		// Autocomplete har inga egna filter som processas!
		return false;
	}
	public reset(): void {
		this.controllerFilters.value = [];
	}
	public removeFilter(filterItem: FilterItem): void {
		throw new Error("Method not implemented.");
	}
	public outputFilters = ref([]);
}
