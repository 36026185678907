import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-txtaccent font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createBlock(_component_Panel, {
    toggleable: _ctx.filterSectionToggleable,
    collapsed: _ctx.filterSectionCollapsed,
    "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filterSectionCollapsed = $event)),
    class: "filter-section mt-4"
  }, {
    header: _withCtx(() => [
      _createVNode("a", {
        class: ["filter-header", { 'filter-header-clickable': _ctx.filterSectionToggleable }],
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onHeaderClick && _ctx.onHeaderClick(...args)))
      }, [
        _createVNode("div", _hoisted_1, _toDisplayString(_ctx.filterSectionTitle), 1)
      ], 2)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["toggleable", "collapsed"]))
}