
import { defineComponent } from "vue";
import SearchBar from "@/components/SearchBar.vue"; // @ is an alias to /src

export default defineComponent({
	name: "Search",
	components: {
		SearchBar,
	},
});
