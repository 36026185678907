
import { defineComponent } from "vue";
import Markdown from "@/components/Markdown.vue";

export default defineComponent({
	name: "MarkdownBlock",
	components: {
		Markdown,
	},
	props: {
		header: {
			type: String,
			required: false,
		},
		markdown: {
			type: String,
		},
		headerTag: {
			type: String,
			default: "h2",
		},
	},
});
