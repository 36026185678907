class BasenUtil {
	/**
	 * Portning av funktionen KodaRiksNr i access_functions.incl.php
	 * @param riksNr
	 * @returns
	 */
	protected KodaRiksNr(riksNr: number): string {
		return (
			((Math.random() + 1) * 1e5).toString(16).substr(1, 4) +
			(12689 - riksNr).toString(16) +
			((Math.random() + 1) * 1e5).toString(16).substr(1, 3)
		);
	}
	public getCaveUrl(riksNr: number) {
		return `http://basen.speleo.se/rel/cave_info.php?RndIndex=${this.KodaRiksNr(riksNr)}`;
	}
}

export default new BasenUtil();
