import { reactive, readonly } from "vue";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StoreData {}

// https://medium.com/@mario.brendel1990/vue-3-the-new-store-a7569d4a546f

export abstract class Store<T extends StoreData> {
	protected state: T;

	constructor() {
		const data = this.data();
		this.state = reactive(data) as T;
		this.setup();
	}

	protected abstract data(): T;

	// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
	protected setup(): void {}

	public getState(): T {
		return readonly(this.state) as T;
	}
}
