import { StoreData, Store } from "./Store";

interface SearchData extends StoreData {
	searchString: string;
}

class SearchStore extends Store<SearchData> {
	protected data(): SearchData {
		return {
			searchString: "",
		};
	}

	public setSearchString(searchString: string) {
		this.state.searchString = searchString;
	}
}

export default new SearchStore();
