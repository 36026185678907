
import { defineComponent } from "vue";
import UserUtil from "@/util/UserUtil";
import { api, useApi } from "@/api/Api";
import { useToast } from "primevue/usetoast";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "NavBar",
	props: {
		msg: String,
	},
	setup() {
		const toast = useToast();
		const route = useRoute();
		const logout = async () => {
			if (await api.logout()) {
				toast.add({
					severity: "info",
					summary: "Utloggad!",
					life: 4000,
				});
			}
		};
		const login = () => {
			// Tas till https://speleo.se/ssf-login
			// Med redirect to: /grottor-se-demo/
			// som har skapar jwt med redirect_to: current route.fullPath.
				window.location.href = `${process.env!.VUE_APP_LOGIN_URL}?redirect_to=${encodeURIComponent(
				`${process.env!.VUE_APP_LOGIN_JWT_PAGE}?referer=${encodeURIComponent(route.fullPath)}`,
			)}`;
		};
		return { ...useApi(), logout, login, UserUtil };
	},
});
